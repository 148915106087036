@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #54555990 #ad242400;
  -webkit-font-smoothing: antialiased;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ad242400;
}

*::-webkit-scrollbar-thumb {
  background-color: #545559ab;
  border-radius: 20px;
  border: none;
}

.styles-japanese-font {
  font-family: 'Hiragino Kaku Gothic Pro W3', 'Hiragino Kaku Gothic Pro',
    'Yu Gothic', 'Osaka', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

.start-signup-button {
  /* Used for GTM tracking */
}

#complete-signup-step {
  /* Used for GTM tracking */
}

.start-stripe-subscription-button {
  /* Used for GTM tracking */
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}
